@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-heavy-webfont.woff2") format("woff2");
  font-weight: 900;
}
@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-extrabold-webfont.woff2") format("woff2");
  font-weight: 800;
}
@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-bold-webfont.woff2") format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-demibold-webfont.woff2") format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-medium-webfont.woff2") format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-regular-webfont.woff2") format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-light-webfont.woff2") format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-extralight-webfont.woff2") format("woff2");
  font-weight: 200;
}
@font-face {
  font-family: "greycliff-cf";
  src: url("fonts/greycliffcf-thin-webfont.woff2") format("woff2");
  font-weight: 100;
}

// @font-face {
//     font-family: "HeaderFont";
//       src: url("fonts/greycliffcf-demibold-webfont.woff2") format("opentype")
//     }
//     // Use font-weight: 600

//   @font-face {
//     font-family: "SubHeaderFont";
//       src: url("fonts/greycliffcf-medium-webfont.woff2") format("opentype")
//     }
//     // Use font-weight: 500

//   @font-face {
//     font-family: "BodyFont";
//       src: url("fonts/greycliffcf-regular-webfont.woff2") format("opentype")
//     }
//     // Use font-weight: 400

//   @font-face {
//     font-family: "LightFont";
//       src: url("fonts/greycliffcf-light-webfont.woff2") format("opentype")
//     }
//     // Use font-weight: 300

//   @font-face {
//     font-family: "ExtraLightFont";
//       src: url("fonts/greycliffcf-extralight-webfont.woff2") format("opentype")
//     }
//     // Use font-weight: 200

//   @font-face {
//     font-family: "NavFont";
//       src: url("fonts/greycliffcf-medium-webfont.woff2") format("opentype")
//     }
