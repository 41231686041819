// Responsive Breakpoint Values
// make sure these match Bootstraps values
// We are using this separately so we don't
// have to import Bootstrap into each component
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// Responsive Breakpoint Mixins
// Example usage: @include breakpoint(md) {}
@mixin breakpoint($class) {
  @if $class == xs {
    @media (min-width: $xs) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: $sm) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $md) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $lg) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: $xl) {
      @content;
    }
  } @else if $class == xxl {
    @media (min-width: $xxl) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl, and xxl";
  }
}

// Responsive Breakpoint Mixin to focus on selected size and below.
// Example usage: @include breakpoint-down(md) {}
@mixin breakpoint-down($class) {
  @if $class == xs {
    @media (max-width: 575.98px) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: 767.98px) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: 991.98px) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: 1199.98px) {
      @content;
    }
  } @else if $class == xl {
    @media (max-width: 1929.98px) {
      @content;
    }
  } @else {
    @warn "Breakpoint-down mixin supports: xs, sm, md, lg, and xl";
  }
}
